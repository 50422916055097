<template>
  <div>
    <Grid :key="key"
          :service="service"
          route_name="practical_test_teachers">
        <template v-slot:actions="actions">
          <feather-icon
            icon="EyeIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
            v-tooltip.auto="{
              content: 'Abrir avaliação',
              delay: {
                show: 500,
                hide: 500
              }
            }"
            @click="() => openPraticalTest(actions.row)"/>
        </template>
    </Grid>
  </div>
</template>

<script>
import PracticalTestTeacherService from '@/services/api/PracticalTestTeacherService'

export default {
  data: () => ({
    service: null
  }),
  methods: {
    openPraticalTest(entity) {
      const practicalTestId = entity.practical_test_id //practicalTestId
      const practicalTestTeacherId = entity.id //practicalTestTeacherId
      this.$router.push(`/practical_tests/${practicalTestId}/teachers/${practicalTestTeacherId}/students`)
    }
  },
  beforeMount() {
    this.service = PracticalTestTeacherService.build(this.$vs)
  }
}
</script>
